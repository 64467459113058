
import { defineComponent } from 'vue';
import { HttpApiError, attachmentFileBaseUrl } from '@/api/common/httpApiClient';
import store from '@/store';

export default defineComponent({
  name: 'AccountSummaryMenuButton',
  computed: {
    profileImageUrl() {
      const profileImageUrl = store.state.accountStore.profileImageUrl;
      if (profileImageUrl) {
        return {
          backgroundImage: `url(${attachmentFileBaseUrl}${profileImageUrl})`,
        };
      } else {
        return {
          backgroundImage: `url(${require('@/assets/profile.svg')})`,
        };
      }
    },
  },
  methods: {
    async signOut() {
      await store.dispatch('accountStore/signOut')
      .then(() => {
        alert('로그아웃 되었습니다.');
      })
      .catch((error: HttpApiError) => {
        alert(error.getErrorMessage());
      });
    },
    goToMyBlog() {
      this.$router.push(`/${store.state.accountStore.blogId}`);
    },
    goToSetting() {
      this.$router.push('/setting');
    },
  },
});
