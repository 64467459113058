
import { defineComponent, PropType } from 'vue';
import { CommentNotificationDto } from "@/api/models/notification.dtos";

export default defineComponent({
  name: 'CommentNotificationItem',
  props: {
    notification: Object as PropType<CommentNotificationDto>,
  },
  computed: {
    longCreateAt() {
      if (!this.notification?.createdAt) {
        return null;
      }
      const date = new Date(this.notification.createdAt);
      const hours = `${date.getHours()}`.padStart(2, '0');
      const minutes = `${date.getMinutes()}`.padStart(2, '0');
      return `${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}. ${hours}:${minutes}`;
    },
    relatedPostUrl() {
      const simplePost = this.notification?.comment?.post;
      const blogId = simplePost?.author?.blogId;
      const postUrl = simplePost?.postUrl;
      if(!blogId || !postUrl)
        return null;

      return `/${blogId}/posts/${postUrl}`
    },
  },
  methods: {},
});
