
import { defineComponent } from 'vue';
import store from '@/store';
import AccountSummaryMenuButton from '@/components/accounts/AccountSummaryMenuButton.vue';
import NotificationListButton from "@/components/notifications/NotificationListButton.vue";

export default defineComponent({
  name: 'AppHeader',
  components: { NotificationListButton, AccountSummaryMenuButton },
  computed: {
    isLoggedIn(): boolean {
      return store.getters['accountStore/isLoggedIn'] ?? false;
    },
    isDarkTheme(): boolean {
      return store.getters['commonStore/isDarkTheme'] ?? false;
    },
  },
  methods: {
    toggleTheme() {
      store.dispatch('commonStore/toggleTheme');
    },
    clearCachedMainPosts() {
      store.dispatch('cacheStore/clearMainPosts');
    },
  },
});
