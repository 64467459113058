
import { defineComponent } from 'vue';
import { NotificationDto } from "@/api/models/notification.dtos";
import { deleteNotification, getNotifications } from "@/api/notifications";
import { HttpApiError } from "@/api/common/httpApiClient";
import CommentNotificationItem from "@/components/notifications/CommentNotificationItem.vue";

export default defineComponent({
  name: 'NotificationListButton',
  components: { CommentNotificationItem },
  data() {
    return {
      notifications: Array<NotificationDto>(),
      cursorId: '',
      isNoMorePage: true,
      isLoading: false,
    };
  },
  methods: {
    async fetchNotifications(cursorId: string | null = null) {
      try {
        this.isLoading = true;
        await getNotifications(20, cursorId)
        .then((notifications) => {
          if (notifications.first) {
            this.notifications = notifications.data;
          } else {
            notifications.data.forEach((post) => {
              this.notifications.push(post);
            });
          }
          this.cursorId = (!notifications.last && notifications.cursorId) ? notifications.cursorId : '';
          this.isNoMorePage = notifications.last;
        })
        .catch((error: HttpApiError) => {
          alert(error.getErrorMessage());
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteNotification(notificationId: string) {
      try {
        this.isLoading = true;
        await deleteNotification(notificationId)
        .then(() => {
          this.notifications = this.notifications.filter((x) => x.id !== notificationId);
        })
        .catch((error: HttpApiError) => {
          alert(error.getErrorMessage());
        });
      } finally {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.fetchNotifications();
  }
});
